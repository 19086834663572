import {getCurrentPayrun, getPayrollCompany} from "../utils/payroll-helper";

const defaultState = {
  loaded: false,
  company: null,
  paySchedule: null,
  currentPayrun: null,
  runMode: null,
  importData: {
    firstPaycheckStartRow: 0,
    paycheckIncrementer: 0,
  },
  pastProvider: null,
  hasValidationError: false,
};

const UPDATE_RUN_MODE = "UPDATE_RUN_MODE";
const UPDATE_PAYROLL = "UPDATE_PAYROLL";
const UPDATE_RUN_PAYRUN = "UPDATE_RUN_PAYRUN";
const UPDATE_IMPORT = "UPDATE_IMPORT";

export function updateImport(payload) {
  return {type: UPDATE_IMPORT, payload};
}

export function updateRunMode(payload) {
  return {type: UPDATE_RUN_MODE, payload};
}

export function updateHasValidationError(payload) {
  return {type: "UPDATE_VALIDATION", payload};
}

export function updatePayroll(payload) {
  return {type: UPDATE_PAYROLL, payload};
}

export function loadPayroll() {
  return async (dispatch, getState) => {
    dispatch(updatePayroll({loaded: false}));

    let company = await getPayrollCompany();
    let currentPayrun = await getCurrentPayrun();

    dispatch(updatePayroll({company, currentPayrun, loaded: true}));
  };
}

export function setRunMode(newMode) {
  return (dispatch, getState) => {
    dispatch(updateRunMode(newMode));
  };
}

export function updateValidationError(value) {
  return (dispatch, getState) => {
    dispatch(updatePayroll({hasValidationError: value}));
  };
}

export const payrollReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_PAYROLL:
      return {...state, ...payload};
    case UPDATE_RUN_MODE:
      return {...state, runMode: payload};
    case UPDATE_IMPORT:
      return {...state, importData: {...state.importData, ...payload}};
  }
};
