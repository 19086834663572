import {Card, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {useQuery} from "@tanstack/react-query";
import {request} from "../../../utils/request";
import DangerBadge from "../../../components/badges/danger-badge";
import GrayBadge from "../../../components/badges/gray-badge";
import moment from "moment/moment";
import WarningBadge from "../../../components/badges/warning-badge";
import SuccessBadge from "../../../components/badges/success-badge";
import {showErrorNotification, showSuccessNotification} from "../../../utils/notification-helper";

const retrieveStagingRepositories = () => {
  return retrieveRepositories("staging");
};

const retrieveProductionRepositories = () => {
  return retrieveRepositories("production");
};

const retrieveRepositories = async (branch) => {
  console.log(branch);
  return await request("deployments/repositories", "GET", {
    branch,
  });
};

const mergeRepository = async (repositoryUniqueId, branch) => {
  return await request(`deployments/merge/${repositoryUniqueId}`, "POST", {
    branch,
  });
};

export default function ReleasePage() {
  const {
    data: stagingData,
    refetch: stagingRefetch,
    isInitialLoading: stagingIsInitialLoading,
  } = useQuery(["repositories", "staging"], retrieveStagingRepositories);
  const {
    data: productionData,
    refetch: productionRefetch,
    isInitialLoading: productionIsInitialLoading,
  } = useQuery(["repositories", "production"], retrieveProductionRepositories);

  const formatStatus = ({AHEAD_BY, BEHIND_BY}) => {
    if (!(AHEAD_BY || BEHIND_BY)) {
      return <SuccessBadge>Identical</SuccessBadge>;
    }

    const badges = [];

    if (AHEAD_BY) {
      badges.push(<GrayBadge>Ahead by {AHEAD_BY}</GrayBadge>);
    }

    if (BEHIND_BY) {
      badges.push(<WarningBadge>Behind by {BEHIND_BY}</WarningBadge>);
    }

    return badges;
  };

  const mergeStaging = async (repository) => {
    try {
      await mergeRepository(repository.UNIQUE_ID, "staging");

      await showSuccessNotification("Merge Successful", `${repository.NAME} has been merged into staging.`);
    } catch (e) {
      await showErrorNotification(`Error Merging ${repository.NAME}`, "Please try again or look at Github.");
    }

    await stagingRefetch();
  };

  const getColumns = () => {
    return [
      {
        label: "Product",
        value: "NAME",
      },
      {
        label: "Master Status",
        value: "STATUS",
        format: (value, row) => formatStatus(row),
      },
      {label: "Version", value: "VERSION"},
    ];
  };

  const stagingTab = () => {
    return (
      <Card label={"Staging"}>
        <Table
          data={stagingIsInitialLoading ? null : stagingData}
          columns={getColumns()}
          actionButtons={[
            {
              label: "Merge",
              onClick: (row) => mergeStaging(row),
            },
          ]}
        />
      </Card>
    );
  };

  const productionTab = () => {
    return (
      <Card label={"Production"}>
        <Table columns={getColumns()} data={productionIsInitialLoading ? null : productionData} />
      </Card>
    );
  };

  return (
    <div className="m-5">
      <PageHeadings label="Release" />

      <Tab
        data={[
          {id: "staging", label: "Staging"},
          {id: "production", label: "Production"},
        ]}
      >
        {(id) => (
          <div>
            {id === "staging" && stagingTab()}
            {id === "production" && productionTab()}
          </div>
        )}
      </Tab>
    </div>
  );
}
