import React, {Component} from "react";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../redux";

class StepNavCard extends Component {
  loading = false;

  state = {
    currentStep: this.props.defaultStep ? this.props.defaultStep : 0,
    metadata: {},
  };

  async handleClick() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    let result = await this.component.handleClick();

    if (result) {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
      }));
    }

    this.loading = false;
  }

  goBack(steps = 1) {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - steps,
    }));
  }

  render() {
    let {label, steps, router} = this.props;
    let hasError = false;
    if (this.props.payroll?.hasValidationError === true) {
      hasError = true;
    }

    let {currentStep} = this.state;

    let {component, hideBack, nextText} = steps[currentStep];
    const Component = component;

    let buttons = [];

    if (currentStep !== 0 && !hideBack) {
      if (hasError) buttons.push({label: "Back", onClick: () => this.goBack(2)});
      else buttons.push({label: "Back", onClick: () => this.goBack()});
    }

    if (currentStep !== steps.length - 1) {
      buttons.push({
        label: nextText ? nextText : "Next",
        onClick: () => this.handleClick(),
      });
    }

    return (
      <Card label={label} buttons={buttons} noHeaderBorder>
        <div className="flex flex-row justify-around mb-5">
          {steps.map((step, index) => {
            let {label} = step;

            return (
              <div className="w-full mx-6 font-semibold text-sm">
                <div
                  className={classNames(
                    "h-1",
                    index <= currentStep && "bg-indigo-500",
                    index > currentStep && "bg-gray-300"
                  )}
                ></div>
                <div
                  className={classNames(
                    "mt-1",
                    index === currentStep && "",
                    index !== currentStep && "text-gray-500"
                  )}
                >
                  {`${index + 1}. ${label}`}
                </div>
              </div>
            );
          })}
        </div>

        <Component
          router={router}
          navState={this.state}
          updateRender={this.updateRender}
          updateNavState={(payload) => this.setState({...payload})}
          ref={(e) => (this.component = e)}
        />
      </Card>
    );
  }
}

export default setupReduxConnection(["payroll"])(StepNavCard);
