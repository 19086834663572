import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import WasteTypeModal from "../../../../modals/operations/supply-chain/wastes/waste-type-modal";
import {withRouter} from "../../../../utils/navigation";
import SupplyComingSoonPage from "../supply-coming-soon-page";
import {getStore, setupReduxConnection} from "../../../../redux";
import WasteGraph from "../../../../features/operations/supply-chain/waste/waste-graph";
import {WasteRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {isSupplyChainWhitelisted} from "../../../../utils/util";
import {updateWasteTypes} from "../../../../redux/supply";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import {Text} from "@react-pdf/renderer";

class WastesPage extends Component {
  render() {
    const {wasteTypes} = this.props.supply;
    const DEFAULT_WASTE_TYPES = ["COUNT", "RESET", "EXPIRE"];

    if (!wasteTypes) {
      return <Loading />;
    }

    if (!isSupplyChainWhitelisted()) {
      return <SupplyComingSoonPage />;
    }

    const trashWasteType = {
      onClick: async (wasteType) => {
        if (DEFAULT_WASTE_TYPES.includes(wasteType.TYPE)) return;
        var close = {};
        try {
          close = await showLoadingConfirmAlert(
            `Delete Waste Type: ${wasteType.NAME}`,
            "Are you sure you want to delete this waste type? This cannot be undone and may impact " +
              "old and active waste events and inventory/stock."
          );
          await WasteRequests.deleteWasteType(wasteType.ID);
          await getStore().dispatch(updateWasteTypes());
          close();
          await showSuccessNotification(`Waste Type ${wasteType.NAME} successfully deleted.`);
        } catch (e) {
          await showSuccessNotification(`Waste Type ${wasteType.NAME} not deleted.`);
          close();
        }
      },
      format: (wasteType) => {
        return (
          <FontAwesomeIcon
            icon="fa-regular fa-trash"
            color={DEFAULT_WASTE_TYPES.includes(wasteType.TYPE) ? "white" : "red"}
          />
        );
      },
    };

    const editWasteType = {
      onClick: (wasteType) => {
        if (DEFAULT_WASTE_TYPES.includes(wasteType.TYPE)) return;
        this.wasteModal.open(wasteType);
      },
      format: (wasteType) => {
        return (
          <Text style={{color: DEFAULT_WASTE_TYPES.includes(wasteType.TYPE) ? "white" : "text-indigo-600"}}>
            Edit
          </Text>
        );
      },
    };

    const viewWasteType = {
      label: "View",
      onClick: (wasteType) => this.props.router.navigate("/waste/" + wasteType.UNIQUE_ID),
    };

    return (
      <div>
        <WasteTypeModal
          ref={(e) => (this.wasteModal = e)}
          syncState={() => {
            getStore().dispatch(updateWasteTypes());
          }}
        />

        <PageHeadings
          label="Waste Management"
          description="Create and view waste types"
          breadcrumbs={[{label: "Supply Chain", url: "/recipes"}]}
          buttons={[
            {
              label: "Create Waste Type",
              onClick: () => this.wasteModal.open(),
            },
          ]}
        />

        <WasteGraph data={wasteTypes} />

        <Table
          data={wasteTypes.toSorted((a, b) => a.TYPE.localeCompare(b.TYPE))}
          className="mt-4"
          rowsPerPage
          columns={[
            {label: "Name", value: "NAME"},
            {label: "Description", value: "DESCRIPTION"},
            {label: "Number of Events", value: "WASTES", format: (val) => val.length},
          ]}
          actionButtons={[trashWasteType, editWasteType, viewWasteType]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "supply"])(withRouter(WastesPage));
