import React, {Component, useEffect} from "react";
import {Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import EditPayrollGroupModal from "../../modals/payroll/edit-group-modal";
import {setupReduxConnection} from "../../redux";
import {loadPayrollGroups} from "../../redux/admin";
import LoadingSpinner from "../../components/loading-spinner";

export const Payroll_Type_Labels = {
  SINGLE: "Single",
  INDEPENDENT: "Independent",
  MULTI: "Multi",
};

class GroupsTab extends Component {
  componentDidMount() {
    this.props.loadPayrollGroups();
  }

  render() {
    const {payrollGroups} = this.props.admin;

    return (
      <Card label="Groups">
        {!payrollGroups && (
          <div className="flex justify-center py-4">
            <LoadingSpinner />
          </div>
        )}

        {payrollGroups && (
          <div>
            <EditPayrollGroupModal ref={(e) => (this.groupModal = e)} group={payrollGroups[0]} />
            <Table
              actionButtons={[
                {
                  label: "Edit",
                  onClick: (row) => this.groupModal.open(row),
                },
              ]}
              data={payrollGroups}
              columns={[
                {
                  label: "Company",
                  value: "COMPANY_NAME",
                },
                {
                  label: "Type",
                  value: "TYPE",
                  format: (value) => Payroll_Type_Labels[value],
                },
                {
                  label: "Locations",
                  value: "LOCATION_COUNT",
                },
              ]}
            />
          </div>
        )}
      </Card>
    );
  }
}

export default setupReduxConnection(["admin"])(GroupsTab);
