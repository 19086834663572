import React, {Component} from "react";
import {getStore, setupReduxConnection} from "../../../../redux";
import {Loading, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {updateIngredients} from "../../../../redux/supply";
import IngredientsTable from "../../../../tables/operations/recipes/supply-chain-inventory-table";
import {ReportingRequests} from "../../../../utils/request-helpers/reporting/reporting-requests";
import ReportingGraphContainer from "../../../../features/reporting/graphs/reporting-graph-container";
import Banner from "../../../../components/banner";
import ReportingSalesGraph, {
  REPORTING_GRAPH_TYPES,
} from "../../../../features/reporting/graphs/reporting-sales-graph";
import SingleReportingTooltip from "../../../../features/reporting/graphs/tooltips/single-reporting-tooltip";
import {parseIdDict, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import {withRouter} from "../../../../utils/navigation";
import QuickStockIngredientModal from "../../../../modals/operations/supply-chain/recipes/quick-stock-ingredient-modal";

class SupplyChainLandingPage extends Component {
  state = {cogsReportData: null, ingredientUsageReportData: {}};

  componentDidMount() {
    getStore().dispatch(updateIngredients());
    this.fetchCogsReportData();
    this.fetchIngredientReportData();
  }

  fetchReportParams() {
    const {location} = this.props.shop;

    return {
      START_EPOCH: moment().subtract(1, "week").valueOf(),
      END_EPOCH: moment().valueOf(),
      LOCATION_ID_ARRAY: [location.ID],
    };
  }

  async fetchCogsReportData() {
    const payload = this.fetchReportParams();
    const cogsReportData = await ReportingRequests.fetchCogsReport(payload);
    this.setState({cogsReportData});
  }

  async fetchIngredientReportData() {
    const payload = this.fetchReportParams();

    const ingredientUsageReportData = await ReportingRequests.fetchIngredientUsageReport(payload);

    this.setState({
      ingredientUsageReportData: parseIdDict(ingredientUsageReportData.NUMBERS, "INGREDIENT_ID"),
    });
  }

  includeModals() {
    return (
      <>
        <QuickStockIngredientModal ref={(e) => (this.quickStockIngredientModal = e)} />
      </>
    );
  }

  renderCogsGraph(cogsReportData) {
    if (!cogsReportData) {
      return <Loading />;
    }

    return (
      <div>
        <ReportingSalesGraph
          height={150}
          displayAsPercentage={true}
          datasets={[
            {
              id: "base",
              rawData: cogsReportData.TOTALS,
              dataKey: "GROSS_MARGIN_PERCENTAGE",
              countKey: "NET_SALES",
              extraKeys: [
                {
                  keyValue: "COGS",
                  keyName: "costOfGoods",
                },
              ],
              stroke: "#4e46e5",
              strokeWidth: 2,
              fill: "#FFFFFF00",
              fillOpacity: 0.7,
              stopNow: true,
              type: "monotone",
            },
          ]}
          tooltip={
            <SingleReportingTooltip
              formatLabel={(payload) => {
                return payload[`baseDate`];
              }}
              rows={[
                {
                  label: "Sales",
                  formatValue: (payload) => {
                    return toDollars(payload[`baseCount`], true);
                  },
                },
                {
                  label: "Cost of Goods",
                  formatValue: (payload) => {
                    return toDollars(payload[`costOfGoods`], true);
                  },
                },
                {
                  label: "Gross Margin",
                  formatValue: (payload) => (payload[`baseValue`] * 100).toFixed(2) + "%",
                },
              ]}
            />
          }
          type={REPORTING_GRAPH_TYPES.AREA.id}
          dataKey={"GROSS_MARGIN"}
          countKey={"PAYMENT_COUNT"}
          ignoreWeekdayLabels={true}
        />
      </div>
    );
  }

  renderGraphs() {
    const {cogsReportData} = this.state;

    return <ReportingGraphContainer className="mb-4" graph={this.renderCogsGraph(cogsReportData)} />;
  }

  convertToDisplayUnitQuantity(ingredient, quantity = 0) {
    const displayUnitId = ingredient.DISPLAY_UNIT_ID;

    if (displayUnitId !== ingredient.UNIT_ID) {
      const conversion = ingredient.UNIT?.CONVERSIONS?.find((_conversion) => {
        return _conversion.TO_UNIT === displayUnitId;
      });

      return (quantity * conversion?.RATE).toFixed(2);
    }

    return quantity;
  }

  fetchNameColumn() {
    return {value: "NAME", label: "Ingredient"};
  }

  fetchCurrentStockColumn() {
    return {
      value: "DISPLAY_STOCK",
      label: "Current Inventory",
      format: (val, row) => {
        return `${val} ${row.DISPLAY_UNIT?.NAME ?? ""}`;
      },
    };
  }

  fetchPARColumn() {
    return {
      label: "PAR",
      value: "PAR",
      format: (val, ingredient) => {
        return this.convertToDisplayUnitQuantity(ingredient, val) + " " + ingredient.DISPLAY_UNIT?.NAME;
      },
      empty: "-",
    };
  }

  fetchQuantityUsedThisWeek(ingredient) {
    const {ingredientUsageReportData} = this.state;

    return (
      this.convertToDisplayUnitQuantity(
        ingredient,
        ingredientUsageReportData[ingredient.ID]?.TIMESPAN.QUANTITY_SOLD
      ) +
      " " +
      (ingredient.DISPLAY_UNIT?.NAME ?? "")
    );
  }

  fetchUsedThisWeekColumn() {
    return {
      label: "Sold this Week",
      value: "",
      format: (_, ingredient) => this.fetchQuantityUsedThisWeek(ingredient),
    };
  }

  fetchCategoriesColumn() {
    return {
      width: 1,
      value: "CATEGORIES",
      sortable: false,
      label: "Groups",
      format: (categories) => {
        return categories.length === 0 ? "-" : categories.map((category) => category.NAME).join(", ");
      },
    };
  }

  fetchRecommendedRestockQuantity(ingredient) {
    const {ingredientUsageReportData} = this.state;
    const usedThisWeek = ingredientUsageReportData[ingredient.ID]?.TIMESPAN.QUANTITY_SOLD;

    if (ingredient.PAR != null && usedThisWeek != null) {
      return this.convertToDisplayUnitQuantity(
        ingredient,
        Math.max(ingredient.PAR - ingredient.CURRENT_STOCK + usedThisWeek, 0)
      );
    }

    return null;
  }

  fetchRecommendedStockColumn() {
    return {
      label: "Recommended Restock Quantity",
      value: "",
      format: (_, ingredient) => {
        return this.fetchRecommendedRestockQuantity(ingredient)
          ? this.fetchRecommendedRestockQuantity(ingredient) + " " + ingredient.DISPLAY_UNIT?.NAME
          : "-";
      },
    };
  }

  fetchColumns() {
    return [
      this.fetchNameColumn(),
      this.fetchCurrentStockColumn(),
      this.fetchPARColumn(),
      this.fetchUsedThisWeekColumn(),
      this.fetchRecommendedStockColumn(),
      this.fetchCategoriesColumn(),
    ];
  }

  fetchQuickStockButton() {
    return {
      label: "Quick Stock",
      onClick: (ingredient) => {
        this.quickStockIngredientModal.open(ingredient);
      },
    };
  }

  fetchViewButton() {
    return {
      label: "View",
      onClick: (ingredient) => this.props.router.navigate("/recipes/ingredients/" + ingredient.UNIQUE_ID),
    };
  }

  fetchActionButtons() {
    return [this.fetchQuickStockButton(), this.fetchViewButton()];
  }

  renderTable() {
    const {ingredients = []} = this.props.supply;

    return (
      <IngredientsTable
        actionButtons={this.fetchActionButtons()}
        className="mt-4"
        data={ingredients}
        columns={this.fetchColumns()}
      />
    );
  }

  renderPageHeadings() {
    return (
      <div className="my-4">
        <PageHeadings
          className="my-24"
          label="Supply Chain"
          description="Tools for managing inventory, cost of goods, vendors, and waste."
        />
      </div>
    );
  }

  renderBanner() {
    return (
      <Banner label="Supply Chain Units functionality update: Beginning in March, we will be combining the “Display Unit” and “Unit of Measurement” fields on Ingredients into a single “Unit” field. There is no action required on your end as our team will be migrating your current “Display Unit” to the new Unit. The “Units of Measurement” across all ingredients will be saved directly to the recipe, where you can now select any linked unit in a recipe. This simplifies ingredient creation and allows additional flexibility when creating recipes. Please reach out to Dripos Support with any questions!" />
    );
  }

  render() {
    return (
      <div>
        {this.renderBanner()}
        {this.includeModals()}
        {this.renderPageHeadings()}
        {this.renderGraphs()}
        {this.renderTable()}
      </div>
    );
  }
}

export default setupReduxConnection(["supply", "shop"])(withRouter(SupplyChainLandingPage));
