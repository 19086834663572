import {request} from "../utils/request";
import {VendorRequests} from "../utils/request-helpers/supply-chain/supply-chain-requests";
import {PayrollGroupsRequests} from "../utils/request-helpers/admin/payroll-groups-requests";

const defaultState = {
  payrollGroups: null,
  brandedApps: null,
  ranks: null,
};

const UPDATE_PAYROLL_GROUPS = "UPDATE_PAYROLL_GROUPS";
const UPDATE_BRANDED_APPS = "UPDATE_BRANDED_APPS";
const UPDATE_RANKS = "UPDATE_RANKS";

export function updatePayrollGroups(payload) {
  return {type: UPDATE_PAYROLL_GROUPS, payload};
}

export function loadPayrollGroups() {
  return async (dispatch, getState) => {
    const payrollGroups = await PayrollGroupsRequests.loadGroups();

    dispatch(updatePayrollGroups(payrollGroups));
  };
}

export function updateBrandedApps(payload) {
  return {type: UPDATE_BRANDED_APPS, payload};
}

export function updateRanks(payload) {
  return {type: UPDATE_RANKS, payload};
}

export function loadBrandedApps() {
  return async (dispatch, getState) => {
    const brandedApps = await request("branded");

    dispatch(updateBrandedApps(brandedApps));
  };
}

export function loadRanks() {
  return async (dispatch, getState) => {
    const ranks = await request("onboarding/ranks");
    dispatch(updateRanks(ranks));
  };
}

export const adminReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_PAYROLL_GROUPS:
      return {...state, payrollGroups: payload};
    case UPDATE_BRANDED_APPS:
      return {...state, brandedApps: payload};
    case UPDATE_RANKS:
      return {...state, ranks: payload};
  }
};
