import {request} from "../../request";

export const PayrollGroupsRequests = {
  getLocations: async (groupId) => {
    return request("admin/dashboard/group/" + groupId, "GET");
  },
  convertGroup: async (groupId, locations, type) => {
    return request("admin/dashboard/group/convert", "POST", {
      GROUP_ID: groupId,
      LOCATIONS: locations,
      CONVERT_TO_TYPE: type,
    });
  },
  loadGroups: async () => {
    return request("admin/payroll/groups/info", "GET");
  },
};
