import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../../utils/navigation";
import {PurchaseOrderRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class SendPurchaseOrderModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  async sendPO() {
    const {purchaseOrder} = this.props;

    const serverPurchaseOrder = await PurchaseOrderRequests.sendPurchaseOrder(purchaseOrder.ID);

    this.props.updateState(serverPurchaseOrder);
    this.modal && this.modal.close();
  }

  render() {
    const {purchaseOrder} = this.props;

    return (
      <Modal
        deleteOnClick={() => this.modal.close()}
        closeLabel="Cancel"
        buttonLabel="Send Now"
        buttonOnClick={() => this.sendPO()}
        label="Send Purchase Order"
        ref={(e) => (this.modal = e)}
      >
        <div className="font-medium">
          Send purchase order for <span className="font-bold">${toDollars(purchaseOrder.TOTAL)}</span> to{" "}
          {purchaseOrder.VENDOR.NAME}
        </div>

        <div className="mt-2">
          <div className="font-semibold">Recipients</div>

          <div>
            {purchaseOrder.RECIPIENTS.map((c) => {
              return (
                <div>
                  {c.FIRST_NAME} {c.LAST_NAME} ({c.EMAIL})
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(SendPurchaseOrderModal);
