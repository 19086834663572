import {useEffect, useState} from "react";
import {request} from "../../../utils/request";

export function DigestPage() {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const fetchKey = () => {
      request("https://internal.dripos.com/metabase", "GET")
        .then((url) => {
          setUrl(url);
        })
        .catch(() => {
          window.location.href = "/403";
        });
    };

    setInterval(fetchKey, 1000 * 60 * 10);
    fetchKey();
  }, []);

  if (url === null) {
    return <div>Loading</div>;
  }

  console.log("URL", url);

  return (
    <>
      <iframe src={url} frameBorder={0} style={{width: "100%", height: "100vh"}} allowTransparency />
    </>
  );
}
