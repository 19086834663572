export function getURL(url = "") {
  if (url.startsWith("http")) {
    return url;
  }

  if (!!process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL + url;
  }

  return "http://localhost:6969/api/" + url;
}

export function getStripeKey() {
  if (!!process.env.REACT_APP_STRIPE_KEY) {
    return process.env.REACT_APP_STRIPE_KEY;
  }

  return "pk_test_SJXSgpblYrYssIxYRBbWemYg";
}

export function getEnvironment() {
  if (window.location.href.indexOf("qe.order.drip.is") !== -1) {
    return "qe";
  }

  if (window.location.href.indexOf("localhost") === -1) {
    return "production";
  }

  return "test";
}

export function isQE() {
  return window.location.href.indexOf("qe.dripos.com") !== -1;
}

export function isLocal() {
  return window.location.href.indexOf("localhost") !== -1;
}

export function getShortURL() {
  if (window.location.href.indexOf("qe.dripos.com") !== -1) {
    return "https://aapi.dripos.com/";
  }

  if (window.location.href.indexOf("beta.dripos.com") !== -1) {
    return "https://bapi.dripos.com/";
  }

  if (window.location.href.indexOf("staging.dripos.com") !== -1) {
    return "https://testapi.dripos.com/";
  }

  if (window.location.href.indexOf("testdash.dripos.com") !== -1) {
    return "https://testapi.dripos.com/";
  }

  if (window.location.href.indexOf("localhost") === -1) {
    return "https://antiphoton.dripos.com/";
  }

  return "http://localhost:6969/";
}

export function getStripeID() {
  if (window.location.href.indexOf("qe.drip.is") !== -1) {
    return "pk_test_SJXSgpblYrYssIxYRBbWemYg";
  }

  if (window.location.href.indexOf("beta.dripos.is") !== -1) {
    return "pk_live_OQCRXlIgLqB8vC3TQaKr7d6Y";
  }

  if (window.location.href.indexOf("staging.dripos.is") !== -1) {
    return "pk_live_OQCRXlIgLqB8vC3TQaKr7d6Y";
  }

  if (window.location.href.indexOf("localhost") === -1) {
    return "pk_live_OQCRXlIgLqB8vC3TQaKr7d6Y";
  }

  return "pk_test_SJXSgpblYrYssIxYRBbWemYg";
}

export function request(endpoint, method, body, extraHeaders = {}) {
  return new Promise((resolve, reject) => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",

      ...extraHeaders,
    };

    let params = "";
    if (method === "GET" && body) {
      // Trim out null or empty strings
      for (var prop in body) {
        if (body[prop] === null || body[prop] === undefined || body[prop] === "") {
          delete body[prop];
        }
      }
      params =
        "?" +
        Object.keys(body)
          .map((item) => {
            return item + "=" + encodeURIComponent(body[item]);
          })
          .join("&");
      body = null;
    }

    if (typeof window !== "undefined") {
      if (window && typeof window.localStorage["TOKEN"] !== "undefined") {
        headers["Authentication"] = window.localStorage["TOKEN"];
      }

      if (window && typeof window.localStorage["CURRENT_LOCATION"] !== "undefined") {
        headers["Location"] = window.localStorage["CURRENT_LOCATION"];
      }
    }

    if (extraHeaders.Authentication) {
      headers["Authentication"] = extraHeaders.Authentication;
    }

    if (body !== null) {
      fetch(getURL(endpoint), {
        method: method,
        headers: headers,
        body: JSON.stringify(body),
        credentials: "same-origin",
      })
        .then((response) => {
          if (extraHeaders.Accept === "application/pdf") {
            return response.arrayBuffer();
          }

          if (extraHeaders.Accept === "text/html") {
            return response.text();
          }

          return response.json();
        })
        .then((responseJson) => {
          if (extraHeaders.Accept === "application/pdf" || extraHeaders.Accept === "text/html") {
            return resolve(responseJson);
          }

          if (typeof responseJson.success === "undefined") {
            return resolve(responseJson);
          }

          if (responseJson["success"]) {
            resolve(responseJson.data);
          } else {
            reject(responseJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      fetch(getURL(endpoint + params), {
        method: method,
        headers: headers,
        credentials: "same-origin",
      })
        .then((response) => {
          if (extraHeaders.Accept === "application/pdf") {
            return response.arrayBuffer();
          }

          return response.json();
        })
        .then((responseJson) => {
          if (extraHeaders.Accept === "application/pdf") {
            return resolve(responseJson);
          }

          if (typeof responseJson.success === "undefined") {
            return resolve(responseJson);
          }

          if (responseJson["success"]) {
            resolve(responseJson.data);
          } else {
            reject(responseJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

export function stripeUploadFile(file, purpose) {
  return new Promise((resolve, reject) => {
    let headers = {
      Authorization: "Bearer " + "pk_test_SJXSgpblYrYssIxYRBbWemYg",
    };

    let formData = new FormData();
    formData.append("file", file);
    formData.append("purpose", purpose);
    const config = {
      method: "POST",
      headers: headers,
      body: formData,
    };

    fetch("https://uploads.stripe.com/v1/files", config)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson["id"]) {
          resolve(responseJson);
        } else {
          reject(responseJson);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function uploadImage(endpoint, image) {
  return new Promise((resolve, reject) => {
    let headers = {};

    if (typeof window !== "undefined") {
      if (window && typeof window.localStorage["TOKEN"] !== "undefined") {
        headers["Authentication"] = window.localStorage["TOKEN"];
      }

      if (window && typeof window.localStorage["CURRENT_LOCATION"] !== "undefined") {
        headers["Location"] = window.localStorage["CURRENT_LOCATION"];
      }
    }

    let formData = new FormData();
    formData.append("file", image);

    const config = {
      method: "POST",
      headers: headers,
      body: formData,
    };

    fetch(getURL(endpoint), config)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson["success"]) {
          resolve(responseJson.data);
        } else {
          reject(responseJson);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sanitizeName(name) {
  if (!name) {
    return "";
  }

  return name.replaceAll("-", "+").replaceAll(" ", "-");
}

export function deSanitizeName(name) {
  return name.replaceAll("-", " ").replaceAll(/\+/, "-");
}

export function getLocationURL(location) {
  if (!location) {
    return "/";
  }

  return location.SLUG ? location.SLUG : sanitizeName(location.NAME);
}
