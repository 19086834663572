import React, {Component} from "react";
import ReportTable from "./report-table";
import ReportingSearchBar from "./reporting-search-bar";
import Fuse from "fuse.js";
import ReportingValueCell from "./reporting-value-cell";
import {combineArraysIfExist} from "../../../utils/util";
import ReportingLabelCell from "./reporting-label-cell";
import LoadingSpinner from "../../../components/loading-spinner";
import ReportingFilter from "../reporting-filter/reporting-filter";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {
  convertColumnsToCSVHeaders,
  convertEpochsToDateString,
  convertToCSVData,
} from "../../../utils/reporting-helper";
import {CELL_TYPES} from "./reporting-constants";

class FixedColumnReport extends Component {
  state = {
    isLoading: false,
    searchText: "",
    columns: [],
    reportData: null,
    csvString: null,
    csvHeaders: [],
    csvData: [],
    rid: null,
    lastGeneratedCustomParams: {},
  };

  getColumns = () => {
    let {
      constantColumns,
      firstColLabel,
      csvColumns,
      firstColTooltip,
      showLocationsInDownload,
      showDateRangeInDownload,
    } = this.props;

    let columns = constantColumns.map((item) => {
      return {
        ...item,
        format: (value, row) => {
          return (
            <ReportingValueCell
              row={row}
              firstIndex={item.columnSelector}
              style={combineArraysIfExist(row.style, item.style)}
              dollarAmount={item.dollarAmount}
              percentage={item.percentage}
              number={item.number}
              format={item.format}
            />
          );
        },
      };
    });

    columns.unshift({
      label: firstColLabel ? (
        <div className="text-left absolute top-1/2 left-4 transform  -translate-y-1/2">{firstColLabel}</div>
      ) : (
        ""
      ),
      tooltip: firstColTooltip ? firstColTooltip : "",
      width: 50,
      sticky: true,
      format: (value, row) => {
        return <ReportingLabelCell row={row} style={row.style} />;
      },
    });

    let csvHeaders = convertColumnsToCSVHeaders(csvColumns ? csvColumns : columns, "");

    return {columns, csvHeaders};
  };

  onGenerate = async (clearReportData = false) => {
    let {
      endpoint,
      locationPicker,
      groupbyFilter,
      customParams,
      constantColumns,
      csvColumns,
      onGenerate,
      showExcludeThird,
      showLocationsInDownload,
      showDateRangeInDownload,
    } = this.props;
    let {startEpoch, endEpoch, locationIdArray, reportingLocations, excludeThird, groupReports} =
      this.props.reporting;
    const {location} = this.props.shop;

    let newRid = `rid_${Math.random().toString(36).slice(2, 7)}`;

    let payload = {
      rid: newRid,
      lastGeneratedCustomParams: customParams,
    };

    if (!clearReportData) {
      payload.isLoading = true;
      payload.reportData = null;
    }

    this.setState(payload, () => {
      if (onGenerate) {
        this.props.onGenerate();
      }
    });

    let body = {
      START_EPOCH: startEpoch,
      END_EPOCH: endEpoch,
      ...customParams,
    };

    if (showExcludeThird) {
      body.EXCLUDE_THIRD_PARTY = excludeThird;
    }

    if (locationPicker) {
      body.LOCATION_ID_ARRAY = locationIdArray;
    }

    if (groupbyFilter && locationIdArray.length > 1) {
      body.GROUP_REPORTS = groupReports;
    }

    let reportData = await request(endpoint, "POST", body).catch((e) => console.log(e));

    let rows = this.props.convertDataToRows(reportData);

    let csvData = convertToCSVData(csvColumns ? csvColumns : constantColumns, rows);

    if (csvData.length > 0) {
      if (showDateRangeInDownload) {
        const dateRow = {};

        dateRow.LABEL = convertEpochsToDateString(startEpoch, endEpoch, "MM/DD/YY", " - ");

        csvData.unshift(dateRow);
      }

      if (showLocationsInDownload) {
        const selectedLocations = locationPicker
          ? locationIdArray.map((_lid) => reportingLocations[_lid].NAME)
          : [location.NAME];

        const locationRow = {};

        locationRow.LABEL = selectedLocations.toString();

        csvData.unshift(locationRow);
      }
    }

    if (this.state.rid === newRid) {
      await this.setState({isLoading: false, reportData, csvData});
    }
  };

  filterRows(rows) {
    let {searchText} = this.state;

    if (searchText.length > 0) {
      rows = new Fuse(rows, {
        keys: ["label"],
        useExtendedSearch: true,
        threshold: 0.1,
      })
        .search(searchText)
        .map(({item}) => item);
    }

    return rows;
  }

  render() {
    let {isLoading, reportData, csvData} = this.state;

    let {
      search,
      searchText,
      product,
      customFields,
      renderCustomTable,
      hideTime,
      customHeader,
      alwaysShowCustomTable,
      loadingText,
      renderExtraTable,
    } = this.props;

    let {columns, csvHeaders} = this.getColumns();

    let data;

    if (reportData) {
      data = this.props.convertDataToRows(reportData, (reportData) => {
        this.setState({reportData});
      });

      if (search) {
        data = this.filterRows(data);
      }
    }

    return (
      <div>
        <ReportingFilter
          filenamePrefix={this.props.filenamePrefix}
          hideTimeframe={true}
          product={product}
          csvHeaders={csvHeaders}
          csvData={csvData}
          locationPicker={this.props.locationPicker}
          productPicker={this.props.productPicker}
          enableStripePayouts={this.props.enableStripePayouts}
          customFields={customFields}
          hideTime={hideTime}
          isRowClickable={this.props.isRowClickable}
          onRowClick={this.props.onRowClick}
          onGenerate={this.onGenerate}
          customCSVLink={this.props.customCSVLink}
          reportData={reportData}
          hideCSVLink={this.props.hideCSVLink}
          showExcludeThird={this.props.showExcludeThird}
          groupbyFilter={this.props.groupbyFilter}
        />

        {isLoading && <LoadingSpinner loadingText={loadingText} />}

        {customHeader && reportData && customHeader(reportData)}

        {reportData && (!renderCustomTable || alwaysShowCustomTable) && (
          <div>
            {this.props.graph && this.props.graph(reportData)}

            {search && (
              <ReportingSearchBar
                placeholder={searchText}
                onChange={(newText) => this.setState({searchText: newText})}
              />
            )}

            <ReportTable
              data={data}
              constantColumns={columns}
              numbers={reportData}
              expandable={this.props.expandable}
              isExpandable={this.props.isExpandable}
              isRowClickable={this.props.isRowClickable}
              onRowClick={this.props.onRowClick}
            />
          </div>
        )}

        {reportData && renderCustomTable && renderCustomTable(reportData, columns)}

        {reportData && renderExtraTable && renderExtraTable(reportData)}
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "reporting", "shop"])(FixedColumnReport);
