import React, {Component} from "react";
import {AdvancedFilter, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import GiftCardModal from "../../../modals/marketing/gift-cards/gift-card-modal";
import {setupReduxConnection} from "../../../redux";
import ImportGiftCardsModal from "../../../modals/marketing/import/import-gift-cards-modal";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {CSVLink} from "react-csv";

class GiftCardsPage extends Component {
  state = {
    downloadData: "",
    locations: [],
  };

  componentDidMount() {
    request("partner/franchise/locations", "GET").then((locations) => {
      this.setState({locations});
    });
  }

  render() {
    const {downloadData, locations} = this.state;
    const {location} = this.props.shop;
    const {permissions} = this.props.user;
    const hasEditPermission =
      permissions.indexOf("ADMIN_FULL") !== -1 || permissions.indexOf("GIFT_CARDS_EDIT_FULL") !== -1;

    const actionButtons = [];

    if (hasEditPermission) {
      actionButtons.push(
        {
          label: "Export Gift Cards",
          onClick: () => {
            request("cards/export", "POST", null).then((data) => {
              this.setState({downloadData: data}, () => this.csvRef.link.click());
            });
          },
        },
        {
          label: "Import Gift Cards",
          onClick: () => {
            this.importModal.open();
          },
        },
        {
          label: "Create Gift Card",
          onClick: () => {
            this.giftCardModal.open();
          },
        }
      );
    }

    return (
      <div>
        <CSVLink
          ref={(e) => (this.csvRef = e)}
          data={downloadData}
          filename={`gift-cards-${location.NAME.replaceAll(" ", "-")}-${moment().format("MM/DD/YY")}.csv`}
          headers={[
            {label: "Phone Number", key: "PHONE"},
            {label: "Email", key: "EMAIL"},
            {
              label: "Name",
              key: "FULL_NAME",
            },
            {label: "Gift Card Number", key: "PAN_ID"},
            {label: "Balance", key: "BALANCE"},
            {
              label: "Total",
              key: "TOTAL",
            },
            {label: "Date Created", key: "DATE_CREATED"},
            {label: "Reloaded Date", key: "DATE_RELOADED"},
          ]}
        />

        <GiftCardModal
          ref={(e) => (this.giftCardModal = e)}
          updateState={() => this.giftCardsTable?.refetch()}
          onCreate={(row) => this.props.router.navigate("/gifts/" + row.UNIQUE_ID)}
        />

        <ImportGiftCardsModal
          ref={(e) => (this.importModal = e)}
          updateState={() => this.giftCardsTable?.refetch()}
        />

        <PageHeadings
          label="Gift Cards"
          description="View all physical and digital gift cards that have been issued"
          buttons={actionButtons}
        />

        <AdvancedFilter
          className="mt-2"
          data={[
            {
              type: "array",
              data: [
                {
                  label: "Search",
                  placeholder: "Search Name, PAN ID, or Phone",
                  type: "search",
                  id: "search",
                },
                {
                  label: "Balance",
                  placeholder: "Enter Gift Card Balance",
                  type: "search",
                  id: "balance",
                },
              ],
            },
            {
              type: "array",
              data: [
                {
                  label: "Creation Date Start",
                  type: "datetime",
                  id: "after",
                },
                {label: "Creation Date End", type: "datetime", id: "before"},
              ],
            },
            {
              type: "array",
              data: [
                {
                  type: "select",
                  label: "Hide Empty Gift Cards",
                  id: "hide-empty",
                  data: [
                    {label: "Yes", value: true},
                    {label: "No", value: null},
                  ],
                },
                {
                  id: "location",
                  type: "selectMultiple",
                  label: "Purchase Location",
                  data: locations.map((_location) => ({
                    value: _location.ID,
                    label: _location.NAME,
                  })),
                },
              ],
            },
          ]}
        >
          {(filters, search) => (
            <Table
              sortable
              pagination
              filters={filters}
              search={search ?? ""}
              route={"cards/dumb"}
              searchFields={["FULL_NAME", "PHONE", "PAN_ID"]}
              actionButtons={[
                {
                  label: "View",
                  onClick: (row) => this.props.router.navigate("/gifts/" + row.UNIQUE_ID),
                },
              ]}
              columns={[
                {
                  value: "FULL_NAME",
                  label: "Patron",
                  format: (name, row) =>
                    row.ACCOUNT_ID ? (
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {row?.FULL_NAME ? row?.FULL_NAME : "Unnamed Patron"}
                        </div>

                        {row.PHONE ? (
                          <div className="text-sm text-gray-500">{getFormattedPhone(row.PHONE)}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      "-"
                    ),
                },
                {
                  value: "PAN_ID",
                  label: "Pan",
                  format: (pan) =>
                    pan ? (
                      <div className={search === pan && search && pan ? "text-indigo-700 font-semibold" : ""}>
                        {pan}
                      </div>
                    ) : (
                      "-"
                    ),
                },
                {
                  value: "BALANCE",
                  label: "Balance",
                  format: (balance) => "$" + toDollars(balance),
                },
                {
                  width: 1,
                  value: "DATE_CREATED",
                  label: "Date created",
                  mobile: "lg:table-cell",
                  format: (value, _) => {
                    return moment(value).format("MM/DD/YY");
                  },
                },
                {
                  width: 1,
                  value: "DATE_UPDATED",
                  label: "Last used",
                  mobile: "lg:table-cell",
                  format: (value, _) => {
                    return value ? moment(value).format("MM/DD/YY") : "-";
                  },
                },
              ]}
              ref={(e) => (this.giftCardsTable = e)}
            />
          )}
        </AdvancedFilter>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(withRouter(GiftCardsPage));
